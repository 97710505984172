<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_bkddtps',
      lkps_table_id: parseInt(this.$route.params.lkps_table_id),
      headers: [
        [{
          label: 'Nama Lengkap Dosen Tetap',
          dataKey: 'data_master_dosen.full_name',
          order: 1,
          attributes: { rowspan: 2 },
        }, {
          label: 'SKS Pembelajaran pada',
          attributes: { colspan: 3 },
        }, {
          label: 'SKS Penelitian',
          dataKey: 'sks_penelitian',
          order: 5,
          attributes: { rowspan: 2 },
        }, {
          label: 'SKS P2M',
          dataKey: 'sks_p2m',
          order: 6,
          attributes: { rowspan: 2 },
        }, {
          label: 'SKS Manajemen',
          attributes: { colspan: 2 },
        }, {
          label: 'Jumlah sks Beban Kerja',
          dataKey: 'jumlah_sks_beban_kerja',
          order: 9,
          attributes: { rowspan: 2 },
        }],
        [{
          label: 'PS Sendiri (S1, S2, dan S3)',
          dataKey: 'sks_ps_sendiri',
          order: 2,
        }, {
          label: 'PS Lain di PT Sendiri',
          dataKey: 'sks_ps_lain_pt_sendiri',
          order: 3,
        }, {
          label: 'PT Lain',
          dataKey: 'sks_pt_lain',
          order: 4,
        }, {
          label: 'PT Sendiri',
          dataKey: 'sks_manajemen_pt_sendiri',
          order: 7,
        }, {
          label: 'PT Lain',
          dataKey: 'sks_manajemen_pt_lain',
          order: 8,
        }]
      ],
      form: {
        inputs: [{
          label: 'Nama Dosen',
          name: 'data_master_dosen_id',
          async dataKey(data, input) {
            const response = await useJwt.http.get(`data_master_dosen/${data.data_master_dosen_id}?state=all`)
            if (response.data.data_master_dosens) {
              const { id: value, full_name: label } = response.data.data_master_dosens
              input.options = [{ label, value }]
              return value
            }
            return 0
          },
          type: 'select',
          options: [],
          placeholder: 'Cari nama dosen',
          rules: 'required',
          async fetchOptions(search, loading) {
            if (search.length >= 3) {
              loading(true)
              const response = await useJwt.http.get('data_master_dosen', { params: { full_name: search } })
              const fetchedOptions = response.data.data_master_dosens.map(dosen => ({ label: dosen.full_name, value: dosen.id }))
              loading(false)
              return fetchedOptions
            }
          },
          customSelectedValue(data) {
            return data.value
          },
        }, {
          label: 'PS Sendiri (S1, S2, dan S3)',
          name: 'sks_ps_sendiri',
          dataKey: 'sks_ps_sendiri',
          type: 'text',
          rules: 'required'
        }, {
          label: 'PS Lain di PT Sendiri',
          name: 'sks_ps_lain_pt_sendiri',
          dataKey: 'sks_ps_lain_pt_sendiri',
          type: 'text',
          rules: 'required'
        }, {
          label: 'PT Lain',
          name: 'sks_pt_lain',
          dataKey: 'sks_pt_lain',
          type: 'text',
          rules: 'required'
        }, {
          label: 'SKS Penelitian',
          name: 'sks_penelitian',
          dataKey: 'sks_penelitian',
          type: 'text',
          rules: 'required'
        }, {
          label: 'SKS P2M',
          name: 'sks_p2m',
          dataKey: 'sks_p2m',
          type: 'text',
          rules: 'required'
        }, {
          label: 'PT Sendiri',
          name: 'sks_manajemen_pt_sendiri',
          dataKey: 'sks_manajemen_pt_sendiri',
          type: 'text',
          rules: 'required'
        }, {
          label: 'PT Lain',
          name: 'sks_manajemen_pt_lain',
          dataKey: 'sks_manajemen_pt_lain',
          type: 'text',
          rules: 'required'
        }]
      }
    }
  },
}
</script>
